import { render, staticRenderFns } from "./EditionModal.vue?vue&type=template&id=6b9132a0&scoped=true"
import script from "./EditionModal.vue?vue&type=script&lang=js"
export * from "./EditionModal.vue?vue&type=script&lang=js"
import style0 from "./EditionModal.vue?vue&type=style&index=0&id=6b9132a0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b9132a0",
  null
  
)

export default component.exports