var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDisplayed)?_c('Modal',{on:{"close":function($event){return _vm.toggleModal('reminder')}}},[_c('h3',[_vm._v("Choose a reminder token:")]),_c('ul',{staticClass:"reminders"},_vm._l((_vm.availableReminders),function(reminder){return _c('li',{key:reminder.role + ' ' + reminder.name,staticClass:"reminder",class:[reminder.role, { match: _vm.queryMatches(reminder.name) }],on:{"click":function($event){return _vm.addReminder(reminder)}}},[_c('span',{staticClass:"icon",style:({
          backgroundImage: `url(${
            reminder.image && _vm.grimoire.isImageOptIn
              ? (Array.isArray(reminder.image) ? reminder.image[0] : reminder.image)
              : require(
                  '../../assets/icons/' +
                    (reminder.imageAlt || reminder.role) +
                    '.webp',
                )
          })`,
        })}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(reminder.name))])])}),0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"searchInput",staticClass:"reminder-search",attrs:{"placeholder":"Search"},domProps:{"value":(_vm.query)},on:{"keyup":_vm.keyup,"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }