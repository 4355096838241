var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"info"},[_c('li',{staticClass:"edition",class:['edition-' + _vm.edition.id],style:({
      backgroundImage: `url(${
        _vm.edition.logo && _vm.grimoire.isImageOptIn
          ? _vm.edition.logo
          : require('../assets/editions/' + _vm.edition.id + '.webp')
      })`,
    })}),(_vm.players.length - _vm.teams.traveller < 5)?_c('li',[_vm._v("Please add more players!")]):_vm._e(),_c('li',[(!_vm.edition.isOfficial)?_c('span',{staticClass:"meta"},[_vm._v(" "+_vm._s(_vm.edition.name)+" "+_vm._s(_vm.edition.author ? "by " + _vm.edition.author : "")+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.players.length)+" "),_c('font-awesome-icon',{staticClass:"players",attrs:{"icon":"users"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.teams.alive)+" "),_c('font-awesome-icon',{staticClass:"alive",attrs:{"icon":"heartbeat"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.teams.votes)+" "),_c('font-awesome-icon',{staticClass:"votes",attrs:{"icon":"vote-yea"}})],1)]),(_vm.players.length - _vm.teams.traveller >= 5)?_c('li',[_c('span',[_vm._v(" "+_vm._s(_vm.teams.townsfolk)+" "),_c('font-awesome-icon',{staticClass:"townsfolk",attrs:{"icon":"user-friends"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.teams.outsider)+" "),_c('font-awesome-icon',{staticClass:"outsider",attrs:{"icon":_vm.teams.outsider > 1 ? 'user-friends' : 'user'}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.teams.minion)+" "),_c('font-awesome-icon',{staticClass:"minion",attrs:{"icon":_vm.teams.minion > 1 ? 'user-friends' : 'user'}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.teams.demon)+" "),_c('font-awesome-icon',{staticClass:"demon",attrs:{"icon":_vm.teams.demon > 1 ? 'user-friends' : 'user'}})],1),(_vm.teams.traveller)?_c('span',[_vm._v(" "+_vm._s(_vm.teams.traveller)+" "),_c('font-awesome-icon',{staticClass:"traveller",attrs:{"icon":_vm.teams.traveller > 1 ? 'user-friends' : 'user'}})],1):_vm._e(),(_vm.grimoire.isNight)?_c('span',[_vm._v(" Night phase "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'cloud-moon']}})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }